import React from 'react';
import PropTypes from 'prop-types';
import {
    List,
    Edit,
    Create,
    EditButton,
    SimpleForm,
    ArrayInput,
    TextInput,
    SelectInput,
    ReferenceInput,
    Labeled,
} from 'react-admin';
import {Field} from 'redux-form';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import MUIList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import ContentRowsEditor from './ContentRowsEditor';
import AssetBrowser from '../asset-library/AssetBrowser';

const cardStyle = {
    width: 300,
    minHeight: 300,
    margin: '0.5em',
    padding: '0.5em',
    display: 'inline-block',
    verticalAlign: 'top',
};

const ContentPageGrid = props => {
    const {
        ids,
        data,
        basePath,
    } = props;

    return (
        <div style={{margin: '1em'}}>
            {ids.map(id => (
                <Card key={id} style={cardStyle}>
                    <Typography style={{marginLeft: '1em', marginTop: '1em'}} variant="title">
                        {data[id].title} <Chip label={data[id].type.toUpperCase()} />
                    </Typography>
                    <MUIList dense>
                        {data[id].contentRows.map(contentRow => (
                            <ListItem key={contentRow.position}>
                                <ListItemText
                                    primary={contentRow.templateConfiguration.title}
                                    secondary={contentRow.template}
                                />
                            </ListItem>
                        ))}
                    </MUIList>
                    <CardActions style={{textAlign: 'right'}}>
                        <EditButton resource="posts" basePath={basePath} record={data[id]} />
                    </CardActions>
                </Card>
            ))}
        </div>
    );
};

ContentPageGrid.propTypes = {
    ids: PropTypes.array,
    data: PropTypes.object,
    basePath: PropTypes.string,
};

ContentPageGrid.defaultProps = {
    ids: null,
    data: null,
    basePath: '',
};

export const ContentPageList = props => (
    <List {...props} sort={{field: '_id', order: 'DESC'}}>
        <ContentPageGrid />
    </List>
);

export const ContentPageEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="title" />
            <SelectInput
                source="type"
                choices={[
                    {id: 'storefront', name: 'Storefront Page'},
                    {id: 'general', name: 'General Content Page'},
                ]}
            />
            <ArrayInput source="contentRows" label="Content Rows">
                <ContentRowsEditor />
            </ArrayInput>
        </SimpleForm>
    </Edit>
);

export const ContentPageCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="title" />
            <ReferenceInput label="Asset" source="asset.id" reference="assets" perPage={6}>
                <Labeled label="Asset">
                    <Field name="asset" component={AssetBrowser} />
                </Labeled>
            </ReferenceInput>
            <SelectInput
                source="type"
                choices={[
                    {id: 'storefront', name: 'Storefront Page'},
                    {id: 'general', name: 'General Content Page'},
                ]}
            />
            <ArrayInput source="contentRows" label="Content Rows">
                <ContentRowsEditor />
            </ArrayInput>
        </SimpleForm>
    </Create>
);
