import {PUBLISH} from '../data-provider/resourceActions';

export const PUBLISH_VERSION = 'PUBLISH_VERSION';

export const publishVersion = (id, data, basePath) => ({
    type: PUBLISH_VERSION,
    payload: {id, data: {...data, published: true}},
    meta: {
        fetch: PUBLISH,
        resource: 'versions',
        onSuccess: {
            notification: {
                body: 'Version published.',
                level: 'info',
            },
            redirectTo: '/versions',
            basePath,
        },
        onFailure: {
            notification: {
                body: 'Version not published.',
                level: 'warning',
            },
        },
    },
});
