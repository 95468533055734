import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import Menu from '@material-ui/core/Menu/Menu';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AddAboveIcon from '@material-ui/icons/VerticalAlignTop';
import EditIcon from '@material-ui/icons/Edit';
import RemoveIcon from '@material-ui/icons/Delete';
import AddBelowIcon from '@material-ui/icons/VerticalAlignBottom';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/LibraryAdd';
import Drawer from '@material-ui/core/Drawer';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';

import ContentRowForm from './ContentRowForm';
import * as classifications from './contentRowClassifications';
import * as defaultTemplateConfigurations from './defaultTemplateConfigurations';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        spacing: 10,
    },
    gridList: {
        width: 'full',
        height: 800,
    },
    subheader: {
        width: '100%',
    },
});

const cardStyle = {
    width: 500,
    minHeight: 100,
    marginTop: '1em',
    display: 'inline-block',
    verticalAlign: 'top',
    zIndex: 2,
};

const DIRECTION_UP = -1;
const DIRECTION_DOWN = 1;

const SortableItem = SortableElement(({data, index, item, toggleContentRowForm, openActionsMenu}) => (
    <Card style={cardStyle}>
        <CardHeader
            title={item.templateConfiguration.title ? item.templateConfiguration.title : '[No Title]'}
            subheader={`${item.type} | ${item.template}`}
            action={(
                <IconButton
                    key="menu-item-actions-icon"
                    aria-label="More"
                    aria-haspopup="true"
                    onClick={openActionsMenu(item)}
                >
                    <MoreVertIcon />
                </IconButton>
)}
        />
    </Card>
));

const SortableList = SortableContainer(({fields, data, addContentRow, openActionsMenu}) => (
    <div style={{marginTop: '0.7em'}}>
        {fields.map((item, index, data) => (
            <SortableItem
                data={data}
                key={`item-${index}`}
                index={index}
                item={data.get(index)}
                openActionsMenu={openActionsMenu}
            />
        ))}
        { (fields.length === 0)
        && (
            <Button style={{marginTop: '1em'}} variant="contained" size="small" onClick={addContentRow(0)}>
                <AddIcon />
                Add Content Row
            </Button>
        )}
    </div>
));

class ContentRowsEditor extends React.Component {
    static propTypes = {
        fields: PropTypes.object,
    };

    static defaultProps = {
        fields: null,
    };

    state = {
        actionsMenuAnchor: null,
        openContentRowForm: false,
        openDeleteConfirmation: false,
        currentContentRow: null,
        validationErrors: {
            templateConfiguration: {},
        },
    };

    openActionsMenu = contentRow => event => {
        this.setState({
            actionsMenuAnchor: event.currentTarget,
            currentContentRow: contentRow,
        });
    };

    closeActionsMenu = () => {
        this.setState({
            actionsMenuAnchor: null,
        });
    };

    toggleContentRowForm = displayState => () => {
        const state = {
            openContentRowForm: displayState,
            actionsMenuAnchor: null,
        };
        if (!displayState) state.currentContentRow = null;
        this.setState(state);
    };

    onSortEnd = ({oldIndex, newIndex}) => {
        const {fields} = this.props;
        fields.move(oldIndex, newIndex);
        fields.forEach((name, index, fields) => {
            fields.get(index).position = index;
        });
    };

    updateContentRow = (parentField = null) => event => {
        let value = event.target.value;
        const field = event.target.name;
        if (event.target.type === 'checkbox') {
            value = event.target.checked;
        }

        this.setState(prevState => {
            const editedContentRow = prevState.currentContentRow;
            if (parentField) editedContentRow[parentField][field] = value;
            else editedContentRow[field] = value;
            return {
                currentContentRow: editedContentRow,
            };
        });
    };

    addContentRow = (direction = null) => () => {
        const {fields} = this.props;
        const defaultContentRow = {
            id: this.getNewId(),
            display: true,
            position: null,
            type: classifications.CONTENT_ROW_TYPE_MEDIA_COLLECTION,
            value: '',
            template: classifications.CONTENT_ROW_TEMPLATE_MEDIA_CAROUSEL_STANDARD,
            templateConfiguration: defaultTemplateConfigurations.MediaCarouselStandard,
        };

        const contentRow = JSON.parse(JSON.stringify(defaultContentRow));

        let newContentRowPosition;

        if (direction) {
            newContentRowPosition = (direction === DIRECTION_UP)
                ? this.state.currentContentRow.position
                : this.state.currentContentRow.position + 1;
            contentRow.position = newContentRowPosition;
            fields.insert(newContentRowPosition, contentRow);
        } else {
            newContentRowPosition = 0;
            contentRow.position = newContentRowPosition;
            fields.push(contentRow);
        }

        this.shiftContentRows(newContentRowPosition, DIRECTION_DOWN);
        this.setState({
            currentContentRow: contentRow,
            openContentRowForm: true,
            actionsMenuAnchor: null,
        });
    };

    getNewId = () => {
        const {fields} = this.props;
        let max = 0;
        fields.forEach((item, index, rows) => {
            if (rows.get(index).id > max) max = rows.get(index).id;
        });
        return max + 1;
    };

    removeContentRow = () => () => {
        const {fields} = this.props;
        fields.remove(this.state.currentContentRow.position);
        this.shiftContentRows(this.state.currentContentRow.position, DIRECTION_UP);
        this.setState({
            openDeleteConfirmation: false,
            openContentRowForm: false,
        });
    };

    shiftContentRows = (newContentRowPosition, direction = DIRECTION_DOWN) => {
        const {fields} = this.props;
        fields.forEach((item, index, data) => {
            if (index >= newContentRowPosition) {
                data.get(index).position += direction;
            }
        });
    };

    toggleDeleteConfirmation = open => () => {
        this.setState(prevState => ({
            openDeleteConfirmation: open,
            aboutToBeRemovedContentRow: prevState.currentContentRow,
            actionsMenuAnchor: null,
        }));
    };

    validate = contentRow => {
        const errors = {
            templateConfiguration: {},
        };

        if (contentRow.value === '') errors.value = 'Please enter the value';
        if (contentRow.templateConfiguration.mediaLimit === '') {
            errors.templateConfiguration.mediaLimit = 'Please enter numeric value';
        }
        if (contentRow.templateConfiguration.mediaLimitForSubtitle === '') errors.templateConfiguration.mediaLimitForSubtitle = 'Please enter numeric value';

        this.setState({
            validationErrors: errors,
        });
    };

    shouldCancelStart = e => {
        if (['button', 'path', 'svg'].indexOf(e.target.tagName.toLowerCase()) !== -1) {
            return true; // Return true to cancel sorting
        }
    };

    render() {
        const {
            fields,
        } = this.props;

        return (fields) ? (
            <div>
                <Dialog
                    open={this.state.openDeleteConfirmation}
                    onClose={this.toggleDeleteConfirmation(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Remove content row?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            You are about to remove this content row.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.toggleDeleteConfirmation(false)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.removeContentRow(this.state.aboutToBeRemovedContentRow)} color="secondary" autoFocus>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
                <SortableList
                    onSortEnd={this.onSortEnd}
                    fields={fields}
                    axis="y"
                    lockToContainerEdges={true}
                    addContentRow={this.addContentRow}
                    openActionsMenu={this.openActionsMenu}
                    shouldCancelStart={this.shouldCancelStart}
                />
                <Menu
                    id="long-menu"
                    anchorEl={this.state.actionsMenuAnchor}
                    open={Boolean(this.state.actionsMenuAnchor)}
                    onClose={this.closeActionsMenu}
                >
                    <MenuItem key="action-menu-item-add-above" onClick={this.addContentRow(DIRECTION_UP)}>
                        <ListItemIcon>
                            <AddAboveIcon />
                        </ListItemIcon>
                        <ListItemText inset primary="Add Content Row Above" />
                    </MenuItem>
                    <MenuItem key="action-menu-item-edit" onClick={this.toggleContentRowForm(true)}>
                        <ListItemIcon>
                            <EditIcon />
                        </ListItemIcon>
                        <ListItemText inset primary="Edit" />
                    </MenuItem>
                    <MenuItem key="action-menu-item-remove" onClick={this.toggleDeleteConfirmation(true)}>
                        <ListItemIcon>
                            <RemoveIcon />
                        </ListItemIcon>
                        <ListItemText inset primary="Remove" />
                    </MenuItem>
                    <MenuItem key="action-menu-item-add-below" onClick={this.addContentRow(DIRECTION_DOWN)}>
                        <ListItemIcon>
                            <AddBelowIcon />
                        </ListItemIcon>
                        <ListItemText inset primary="Add Content Row Below" />
                    </MenuItem>
                </Menu>
                <Drawer anchor="right" open={this.state.openContentRowForm} onClose={this.toggleContentRowForm(false)}>
                    <ContentRowForm
                        contentRow={this.state.currentContentRow}
                        toggleContentRowForm={this.toggleContentRowForm}
                        updateContentRow={this.updateContentRow}
                        validationErrors={this.state.validationErrors}
                        toggleDeleteConfirmation={this.toggleDeleteConfirmation}
                    />
                </Drawer>
            </div>
        ) : null;
    }
}

export default withStyles(styles)(ContentRowsEditor);
