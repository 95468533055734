/**
 * App config
 */
export default {
    // Services
    FF_API_URI: process.env.VUBIQUITY_FF_API_URI,
    CR_API_URI: process.env.VUBIQUITY_CR_API_URI,

    // Services: CCSearchAPI
    CC_SEARCH_API_URI: process.env.VUBIQUITY_CC_SEARCH_API_URI,
    CC_SEARCH_API_KEY: process.env.VUBIQUITY_CC_SEARCH_API_KEY,
    CC_SEARCH_API_VERSION: process.env.VUBIQUITY_CC_SEARCH_API_VERSION,
    CC_SEARCH_API_PROVIDER_ID: process.env.VUBIQUITY_CC_SEARCH_API_PROVIDER_ID,
    CC_SEARCH_API_PROVIDER: process.env.VUBIQUITY_CC_SEARCH_API_PROVIDER,

    // AzureBlobStorage service
    PUBLISHED_CONFIG_STORAGE_URI: process.env.PUBLISHED_CONFIG_STORAGE_URI,
    PUBLISHED_CONFIG_STORAGE_CONTAINER: process.env.PUBLISHED_CONFIG_STORAGE_CONTAINER,
    PUBLISHED_CONFIG_STORAGE_ENV: process.env.PUBLISHED_CONFIG_STORAGE_ENV,

    // Azure CosmosDB service
    CONFIG_STORAGE_URI: process.env.CONFIG_STORAGE_URI,
    CONFIG_STORAGE_KEY: process.env.CONFIG_STORAGE_KEY,
    CONFIG_STORAGE_ENV: process.env.CONFIG_STORAGE_ENV,
};
