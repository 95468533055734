import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';

import {publishVersion} from './versionActions';

class PublishButton extends Component {
    static propTypes = {
        publishVersion: PropTypes.func.isRequired,
        record: PropTypes.object.isRequired,
    };

    handleClick = () => {
        const {
            publishVersion,
            record,
        } = this.props;
        publishVersion(record.id, record);
    };

    render() {
        return (
            <Button onClick={this.handleClick}>
                Publish
            </Button>
        );
    }
}

export default connect(null, {
    publishVersion,
})(PublishButton);
