import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button} from '@material-ui/core';
import connect from 'react-redux/es/connect/connect';
import DefaultIcon from '@material-ui/icons/Check';

import {setThemeAsDefault} from './themeActions';

class SetDefaultButton extends Component {
    static propTypes = {
        setThemeAsDefault: PropTypes.func.isRequired,
        record: PropTypes.object,
    };

    static defaultProps = {
        record: null,
    };

    handleClick = () => {
        const {
            setThemeAsDefault,
            record,
        } = this.props;
        setThemeAsDefault(record.id, record);
    };

    render() {
        const {
            record,
        } = this.props;

        return (
            (record && record.default === false)
                ? (
                    <Button variant="contained" color="secondary" onClick={this.handleClick}>
                        <DefaultIcon /> &nbsp;
                        Set as Default
                    </Button>
                ) : (
                    <Button disabled>
                        <DefaultIcon /> &nbsp;
                        Default Theme
                    </Button>
                )
        );
    }
}

export default connect(null, {
    setThemeAsDefault,
})(SetDefaultButton);
