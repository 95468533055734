import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import {
    Select,
    MenuItem,
} from '@material-ui/core';
import {Labeled} from 'react-admin';

import actions from '../actions';

class GenreSelect extends Component {
    static propTypes = {
        genres: PropTypes.array,
        input: PropTypes.object,
        value: PropTypes.string,
        onChange: PropTypes.func,
        name: PropTypes.string,
    };

    static defaultProps = {
        genres: [],
        input: null,
        value: '',
        onChange: null,
        name: '',
    };

    render() {
        const {
            genres,
            input,
            value,
            onChange,
            name,
        } = this.props;

        return (
            ((input || value || onChange) && genres) ? (
                <Labeled label="Genre">
                    <Select
                        name={name}
                        value={input ? input.value : value}
                        onChange={input
                            ? value => input.onChange(value)
                            : event => onChange(event)
                        }
                    >
                        {genres.map(genre => (
                            <MenuItem key={`${genre.searchQuery}`} value={`${genre.searchQuery}`}>{genre.name}</MenuItem>
                        ))}
                    </Select>
                </Labeled>
            ) : null
        );
    }
}

const mapStateToProps = state => ({
    genres: state.contentRowProperties.genreHash,
});

const mapDispatchToProps = dispatch => ({
    getGenres: dispatch(actions.getGenres()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GenreSelect);
