import React, {Component} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';

import CollectionSelect from '../content-pages/CollectionSelect';
import GenreSelect from '../content-pages/GenreSelect';

export default class MenuItemForm extends Component {
    static propTypes = {
        menuItem: PropTypes.object,
        toggleInfo: PropTypes.func.isRequired,
        updateMenuItem: PropTypes.func.isRequired,
    };

    static defaultProps = {
        menuItem: null,
    };

    render() {
        const roles = ['PUBLIC', 'LOGGED_IN'];
        const menuItemTypes = [
            {
                key: 'GROUP',
                label: 'Group',
            },
            {
                key: 'CONTAINER',
                label: 'Container',
            },
            {
                key: 'CATEGORY',
                label: 'Category',
            },
            {
                key: 'GENRE',
                label: 'Genre',
            },
            {
                key: 'ALL_MOVIES',
                label: 'All Movies',
            },
            {
                key: 'ALL_TV_SHOWS',
                label: 'All TV Shows',
            },
            {
                key: 'EXTERNAL_URL',
                label: 'External URL',
            },
            {
                key: 'PAGE',
                label: 'Page',
            },
        ];
        const classifications = [
            {
                key: null,
                label: 'All',
            },
            {
                key: 'movies',
                label: 'Movie',
            },
            {
                key: 'tv-shows',
                label: 'TV Show',
            },
        ];
        const menuItemTypesWithClassification = ['CATEGORY', 'GENRE'];
        const {
            menuItem,
            toggleInfo,
            updateMenuItem,
        } = this.props;

        return (
            (menuItem.node)
                ? (
                    <List component="nav">
                        <ListItem style={{width: 400}} key="display">
                            <FormControlLabel
                                control={(
                                    <Switch
                                        name="display"
                                        checked={menuItem.node.display}
                                        onChange={updateMenuItem}
                                    />
                                )}
                                label="Display"
                            />
                        </ListItem>
                        <ListItem style={{width: 400}} key="type">
                            <FormControl disabled={true}>
                                <InputLabel shrink htmlFor="type-helper">
                                    Type
                                </InputLabel>
                                <Select
                                    name="type"
                                    value={menuItem.node.type}
                                    onChange={updateMenuItem}
                                    input={<Input name="type" id="type-helper" />}
                                >
                                    {menuItemTypes.map(type => (
                                        <MenuItem key={type.key} value={type.key}>{type.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </ListItem>
                        <ListItem style={{width: 400}} key="title">
                            <TextField
                                name="title"
                                style={{width: 400}}
                                label="Title"
                                value={menuItem.node.title}
                                onChange={updateMenuItem}
                            />
                        </ListItem>
                        { (menuItem.node.type === 'CATEGORY') && (
                            <ListItem style={{width: 400}} key="value">
                                <CollectionSelect
                                    name="value"
                                    style={{width: 400}}
                                    value={menuItem.node.value}
                                    onChange={updateMenuItem}
                                />
                            </ListItem>
                        )}
                        { (menuItem.node.type === 'GENRE') && (
                            <ListItem style={{width: 400}} key="value">
                                <GenreSelect
                                    name="value"
                                    style={{width: 400}}
                                    value={menuItem.node.value}
                                    onChange={updateMenuItem}
                                />
                            </ListItem>
                        )}
                        { (menuItemTypesWithClassification.includes(menuItem.node.type))
                        && (
                            <ListItem style={{width: 400}} key="classification">
                                <FormControl>
                                    <InputLabel shrink htmlFor="classification-helper">
                                        Classification
                                    </InputLabel>
                                    <Select
                                        displayEmpty
                                        name="classification"
                                        value={menuItem.node.classification}
                                        onChange={updateMenuItem}
                                        input={<Input name="classification" id="classification-helper" />}
                                    >
                                        {classifications.map(classification => (
                                            <MenuItem key={classification.key} value={classification.key}>
                                                {classification.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </ListItem>
                        )}
                        <ListItem style={{width: 400}} key="roles">
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip">Roles</InputLabel>
                                <Select
                                    multiple
                                    name="roles"
                                    value={menuItem.node.roles}
                                    onChange={updateMenuItem}
                                    input={<Input id="select-multiple-chip" />}
                                    renderValue={selected => (
                                        <div>
                                            {selected.map(value => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </div>
                                    )}
                                >
                                    {roles.map(role => (
                                        <MenuItem key={role} value={role}>{role}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </ListItem>
                        <ListItem style={{width: 400}} key="menu-form-save-button" button onClick={toggleInfo(false, null)}>
                            <ListItemText
                                primary="Done"
                            />
                        </ListItem>
                    </List>
                ) : null
        );
    }
}
