import {SET_DEFAULT} from '../data-provider/resourceActions';

export const THEME_SET_AS_DEFAULT = 'THEME_SET_AS_DEFAULT';

export const setThemeAsDefault = (id, data, basePath) => ({
    type: THEME_SET_AS_DEFAULT,
    payload: {id, data: {...data, 'default': true}},
    meta: {
        fetch: SET_DEFAULT,
        resource: 'themes',
        onSuccess: {
            notification: {
                body: 'Default theme set.',
                level: 'info',
            },
            redirectTo: '/themes',
            basePath,
        },
        onFailure: {
            notification: {
                body: 'Default theme not set.',
                level: 'warning',
            },
        },
    },
});
