import {all, throttle, call, put} from 'redux-saga/effects';
import * as actionTypes from './contentRowPropertiesFetchActionTypes';
import {mediaService} from '../services';

/**
 * Fetch collections
 */
const fetchCollectionsSaga = function* fetchCollectionsSaga({payload}) {
    try {
        const results = yield call(mediaService.getMediaCollections, payload);
        yield put({type: actionTypes.GET_COLLECTIONS_SUCCESS, payload: {collectionHash: results}});
    } catch (error) {
        yield put({type: actionTypes.GET_COLLECTIONS_ERROR, error: true, payload: error});
    }
};

/**
 * Fetch genres
 */
const fetchGenresSaga = function* fetchGenresSaga({payload}) {
    try {
        const results = yield call(mediaService.getMediaGenres, payload);
        yield put({type: actionTypes.GET_GENRES_SUCCESS, payload: {genreHash: results}});
    } catch (error) {
        yield put({type: actionTypes.GET_GENRES_ERROR, error: true, payload: error});
    }
};

export function* collectionFetchWatcherSaga() {
    yield all([
        throttle(1000, actionTypes.GET_COLLECTIONS_REQUEST, fetchCollectionsSaga),
    ]);
}

export function* genresFetchWatcherSaga() {
    yield all([
        throttle(1000, actionTypes.GET_GENRES_REQUEST, fetchGenresSaga),
    ]);
}
