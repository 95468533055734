/**
 * Content row properties fetch action types
 */
export const GET_COLLECTIONS_REQUEST = 'GET_COLLECTIONS_REQUEST';
export const GET_COLLECTIONS_SUCCESS = 'GET_COLLECTIONS_SUCCESS';
export const GET_COLLECTIONS_ERROR = 'GET_COLLECTIONS_ERROR';

export const GET_GENRES_REQUEST = 'GET_GENRES_REQUEST';
export const GET_GENRES_SUCCESS = 'GET_GENRES_SUCCESS';
export const GET_GENRES_ERROR = 'GET_GENRES_ERROR';
