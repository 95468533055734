/**
 * Redux actions
 */
import * as mediaSearchActions from './collections/mediaSearchActions';
import * as mediaFetchActions from './collections/mediaFetchActions';
import * as contentRowPropertiesFetchActions from './content-pages/contentRowPropertiesFetchActions';

export default {
    ...mediaSearchActions,
    ...mediaFetchActions,
    ...contentRowPropertiesFetchActions,
};
