import MediaService from '@comrock/vub-wls-media-service';
import MediaProviderSMPInMemory from '@comrock/vub-wls-media-provider-smp-in-memory';
import config from './config';

const mediaProviderSMPInMemory = new MediaProviderSMPInMemory({
    serviceURL: config.CC_SEARCH_API_URI,
    apiKey: config.CC_SEARCH_API_KEY,
    apiVersion: config.CC_SEARCH_API_VERSION,
    providerId: config.CC_SEARCH_API_PROVIDER_ID,
    provider: config.CC_SEARCH_API_PROVIDER,
});

export const mediaService = new MediaService(mediaProviderSMPInMemory);
