/**
 * Media fetch action types
 */
export const GET_MEDIA_ITEMS_REQUEST = 'GET_MEDIA_ITEMS_REQUEST';
export const GET_MEDIA_ITEMS_SUCCESS = 'GET_MEDIA_ITEMS_SUCCESS';
export const GET_MEDIA_ITEMS_ERROR = 'GET_MEDIA_ITEMS_ERROR';

export const GET_MEDIA_ITEM_REQUEST = 'GET_MEDIA_ITEM_REQUEST';
export const GET_MEDIA_ITEM_SUCCESS = 'GET_MEDIA_ITEM_SUCCESS';
export const GET_MEDIA_ITEM_ERROR = 'GET_MEDIA_ITEM_ERROR';
