import React, {Component} from 'react';
import PropTypes from 'prop-types';
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
import VisibleIcon from '@material-ui/icons/Visibility';
import InvisibleIcon from '@material-ui/icons/VisibilityOff';
import GroupIcon from '@material-ui/icons/ViewColumn';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import SortableTree, {addNodeUnderParent, removeNodeAtPath, walk} from 'react-sortable-tree';

import MenuItemForm from './menuItemForm';
import menuItemTypes from './menuItemTypes';
import * as menuTypes from './menuTypes';

const getNodeKey = ({node}) => node.id;

export default class MenuEditor extends Component {
    static propTypes = {
        input: PropTypes.object,
    };

    static defaultProps = {
        input: null,
    };

    constructor(props) {
        super(props);

        this.state = {
            currentNodeData: null,
            aboutToBeRemovedNodeData: null,
            openDetailsPanel: false,
            openDeleteConfirmation: false,
            actionsMenuAnchor: null,
        };
    }

    openActionsMenu = nodeData => event => {
        this.setState({
            actionsMenuAnchor: event.currentTarget,
            currentNodeData: nodeData,
        });
    };

    closeActionsMenu = () => {
        this.setState({
            actionsMenuAnchor: null,
        });
    };

    toggleInfo = open => () => {
        this.setState({
            openDetailsPanel: open,
            actionsMenuAnchor: null,
        });
    };

    toggleDeleteConfirmation = open => () => {
        this.setState(prevState => ({
            openDeleteConfirmation: open,
            aboutToBeRemovedNodeData: prevState.currentNodeData,
            actionsMenuAnchor: false,
        }));
    };

    addChildNode = type => () => {
        const defaultNode = {
            id: this.getNewId(),
            display: 'true',
            title: 'New Child',
            subTitle: 'New Child Sub',
            expanded: true,
            children: [],
            roles: ['PUBLIC'],
            value: '',
            classification: null,
            type: type,
        };

        const parentNode = this.state.currentNodeData.node;

        const {input} = this.props;

        const newNode = JSON.parse(JSON.stringify(defaultNode));

        const {treeData} = addNodeUnderParent({
            treeData: input.value,
            parentKey: parentNode.id,
            expandParent: true,
            getNodeKey: ({node}) => node.id,
            newNode: newNode,
        });

        this.updateTree(treeData);
        this.setState({
            currentNodeData: {node: newNode},
            openDetailsPanel: true,
            actionsMenuAnchor: null,
        });
    };

    removeNode = nodeData => () => {
        const {input} = this.props;
        const treeData = removeNodeAtPath({
            treeData: input.value,
            path: nodeData.path,
            getNodeKey: nodeData => nodeData.node.id,
            ignoreCollapsed: false,
        });
        this.updateTree(treeData);
        this.setState({
            openDeleteConfirmation: false,
            actionsMenuAnchor: null,
        });
    };

    updateTree = treeData => {
        const {
            input,
        } = this.props;
        input.onChange(treeData);
    };

    updateMenuItem = event => {
        let value = event.target.value;
        const name = event.target.name;
        const type = event.target.type;

        if (type === 'checkbox') {
            value = event.target.checked;
        }

        this.setState(prevState => {
            const editedNodeData = prevState.currentNodeData;
            editedNodeData.node[name] = value;
            return {
                currentNodeData: editedNodeData,
            };
        });
    };

    isDroppable = node => {
        const newParent = node.nextParent;
        const oldParent = node.prevParent;
        // disable moving items from e.g. group to container
        if (newParent && newParent.type === oldParent.type) {
            return true;
        }
        return false;
    };

    renderSubtitle = nodeData => (
        <div className="label-area">
            <span className="my-text top-left">{(nodeData.node.display) ? <VisibleIcon style={{fontSize: 16}} /> : <InvisibleIcon style={{fontSize: 16}} />}</span>
            <span className="my-text top-right">
                {(nodeData.node.type === 'group') ? <GroupIcon style={{fontSize: 16}} /> : null}
            </span>
        </div>
    );

    getNewId = () => {
        const {input} = this.props;
        let maxId = 0;
        walk({
            treeData: input.value,
            getNodeKey,
            callback: nodeData => {
                const node = nodeData.node;
                if (node.id > maxId) maxId = node.id;
            },
            ignoreCollapsed: false,
        });
        return maxId + 1;
    };

    getPossibleChildItemTypes = () => {
        let childTypes = [];
        const currentNodeData = this.state.currentNodeData;
        switch (currentNodeData.node.type) {
            case menuItemTypes.GROUP:
                childTypes = [
                    menuItemTypes.CATEGORY,
                    menuItemTypes.GENRE,
                    menuItemTypes.PAGE,
                    menuItemTypes.ALL_MOVIES,
                    menuItemTypes.ALL_TV_SHOWS,
                ];
                if (currentNodeData.node.value !== menuTypes.FOOTER_MENU
                    && currentNodeData.path
                    && currentNodeData.path.length < 2) childTypes.push(menuItemTypes.CONTAINER);
                return childTypes;
            case menuItemTypes.CONTAINER:
                return [menuItemTypes.GROUP];
            default:
                return [];
        }
    };

    render() {
        const {input} = this.props;

        return (input.value) ? (
            <div>
                <Dialog
                    open={this.state.openDeleteConfirmation}
                    onClose={this.toggleDeleteConfirmation(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Remove&nbsp;
                        {(this.state.aboutToBeRemovedNodeData !== null) ? '"' + this.state.aboutToBeRemovedNodeData.node.title + '"' : null} menu item?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            You are about to remove this menu item and all it&apos;s children.
                            This operation is irreversible.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.toggleDeleteConfirmation(false)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.removeNode(this.state.aboutToBeRemovedNodeData)} color="secondary" autoFocus>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
                <div style={{width: 800, height: 800, fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Arial', 'sans-serif'], fontWeight: 'normal'}}>
                    <SortableTree
                        treeData={input.value}
                        onChange={treeData => this.updateTree(treeData)}
                        rowHeight={80}
                        generateNodeProps={nodeData => ({
                            subtitle: this.renderSubtitle(nodeData),
                            buttons: [
                                <IconButton
                                    key="menu-item-actions-icon"
                                    aria-label="More"
                                    aria-haspopup="true"
                                    onClick={this.openActionsMenu(nodeData)}
                                >
                                    <MoreVertIcon />
                                </IconButton>,
                            ],
                        })
                        }
                        getNodeKey={({node}) => node.id}
                        canDrop={node => this.isDroppable(node)}
                    />
                    <Menu
                        id="long-menu"
                        anchorEl={this.state.actionsMenuAnchor}
                        open={Boolean(this.state.actionsMenuAnchor)}
                        onClose={this.closeActionsMenu}
                    >
                        {(this.state.currentNodeData && this.getPossibleChildItemTypes().map(type => (
                            <MenuItem key={`action-menu-item-${type}`} onClick={this.addChildNode(type)}>Add {type.toLowerCase().replace(/_/g, ' ')}</MenuItem>
                        )))}
                        {(this.state.currentNodeData && this.state.currentNodeData.parentNode)
                            ? (
                                [
                                    <MenuItem key="action-menu-item-edit" onClick={this.toggleInfo(true)}>Edit</MenuItem>,
                                    <MenuItem key="action-menu-item-remove" onClick={this.toggleDeleteConfirmation(true)}>Remove</MenuItem>,
                                ]
                            ) : null}
                    </Menu>
                </div>
                <Drawer anchor="right" open={this.state.openDetailsPanel} onClose={this.toggleInfo(false)}>
                    <MenuItemForm
                        menuItem={this.state.currentNodeData}
                        updateMenuItem={this.updateMenuItem}
                        toggleInfo={this.toggleInfo}
                    />
                </Drawer>
            </div>
        ) : null;
    }
}
