import * as actionTypes from './contentRowPropertiesFetchActionTypes';

const initialState = {
    collectionHash: null,
    genreHash: null,
    isFetchInProgress: false,
};

/**
 * Content row properties fetch reducer
 *
 * @param state
 * @param action
 * @returns {Object}
 */
const applicationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_COLLECTIONS_SUCCESS:
            state = {
                ...state,
                collectionHash: action.payload.collectionHash,
                isFetchInProgress: false,
            };
            break;
        case actionTypes.GET_COLLECTIONS_REQUEST:
            state = {
                ...state,
                isFetchInProgress: true,
            };
            break;
        case actionTypes.GET_COLLECTIONS_ERROR:
            state = {
                ...state,
                isFetchInProgress: true,
            };
            break;
        case actionTypes.GET_GENRES_SUCCESS:
            state = {
                ...state,
                genreHash: action.payload.genreHash,
                isFetchInProgress: false,
            };
            break;
        case actionTypes.GET_GENRES_REQUEST:
            state = {
                ...state,
                isFetchInProgress: true,
            };
            break;
        case actionTypes.GET_GENRES_ERROR:
            state = {
                ...state,
                isFetchInProgress: true,
            };
            break;
        default:
        // no-op
    }
    return state;
};

export default applicationReducer;
