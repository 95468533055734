import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {TextField} from '@material-ui/core';
import {Labeled} from 'react-admin';

class MediaSetInput extends Component {
    static propTypes = {
        input: PropTypes.object,
        value: PropTypes.string,
        onChange: PropTypes.func,
        name: PropTypes.string,
    };

    static defaultProps = {
        input: null,
        value: '',
        onChange: null,
        name: '',
    };

    render() {
        const {
            input,
            value,
            onChange,
            name,
        } = this.props;

        return (input || value || onChange) ? (
            <Labeled label="Media set">
                <TextField
                    name={name}
                    value={input ? input.value : value}
                    onChange={input
                        ? value => input.onChange(value)
                        : event => onChange(event)}
                    fullWidth={true}
                />
            </Labeled>
        ) : null;
    }
}

export default MediaSetInput;
