import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {
    TextInput,
    NumberInput,
    ReferenceInput,
    BooleanInput,
    SelectInput,
    Labeled,
} from 'react-admin';
import {ColorInput} from 'react-admin-color-input';

import AssetBrowser from '../asset-library/AssetBrowser';
import {contentRowSlideTemplates} from './contentRowClassifications';

export const MediaCarouselStandardForm = props => {
    const {
        contentRow,
    } = props;
    const index = contentRow.position;

    return (
        contentRow ? (
            <Fragment>
                <TextInput label="Title" source={`contentRows[${index}].templateConfiguration.title`} />
                <SelectInput
                    label="Slide Template"
                    source={`contentRows[${index}].templateConfiguration.slideTemplate`}
                    choices={contentRowSlideTemplates}
                    optionText="label"
                    optionValue="key"
                />
                <SelectInput
                    label="Featured Slide Template"
                    source={`contentRows[${index}].templateConfiguration.featuredSlideTemplate`}
                    choices={contentRowSlideTemplates}
                    optionText="label"
                    optionValue="key"
                />
                <BooleanInput
                    label="Display See More Slide"
                    source={`contentRows[${index}].templateConfiguration.displaySeeMoreSlide`}
                />
                <NumberInput
                    label="Media Limit"
                    source={`contentRows[${index}].templateConfiguration.mediaLimit`}
                    InputProps={{inputProps: {min: 0, max: 10, required: true}}}
                />
                <NumberInput
                    label="Media Limit For Subtitle"
                    source={`contentRows[${index}].templateConfiguration.mediaLimitForSubtitle`}
                />
            </Fragment>
        ) : null
    );
};

MediaCarouselStandardForm.propTypes = {
    contentRow: PropTypes.object,
};

MediaCarouselStandardForm.defaultProps = {
    contentRow: null,
};

export const MediaHeroBannerStandard = props => {
    const {
        contentRow,
    } = props;
    const index = contentRow.position;

    return (
        contentRow ? (
            <Fragment>
                <NumberInput label="Slider Speed" source={`contentRows[${index}].templateConfiguration.sliderSpeed`} />
            </Fragment>
        ) : null
    );
};

MediaHeroBannerStandard.propTypes = {
    contentRow: PropTypes.object,
};

MediaHeroBannerStandard.defaultProps = {
    contentRow: null,
};

export const HeroBannerBackgroundPlayer = props => {
    const {
        contentRow,
    } = props;
    const index = contentRow.position;

    return (
        contentRow ? (
            <Fragment>
                <NumberInput label="Playback delay" source={`contentRows[${index}].templateConfiguration.playbackDelay`} />
            </Fragment>
        ) : null
    );
};

HeroBannerBackgroundPlayer.propTypes = {
    contentRow: PropTypes.object,
};

HeroBannerBackgroundPlayer.defaultProps = {
    contentRow: null,
};

export const MediaStripBannerStandard = props => {
    const {
        contentRow,
    } = props;
    const index = contentRow.position;

    return (
        contentRow ? (
            <Fragment>
                <TextInput label="Title" source={`contentRows[${index}].templateConfiguration.title`} />
                <ColorInput label="Background Color" source={`contentRows[${index}].templateConfiguration.backgroundColor`} />
                <ReferenceInput label="Banner Image" source={`contentRows[${index}].templateConfiguration.bannerImage`} reference="assets" perPage={6}>
                    <Labeled>
                        <Field name="asset" component={AssetBrowser} />
                    </Labeled>
                </ReferenceInput>
                <ReferenceInput label="Banner Logo" source={`contentRows[${index}].templateConfiguration.bannerLogo`} reference="assets" perPage={6}>
                    <Labeled>
                        <Field name="asset" component={AssetBrowser} />
                    </Labeled>
                </ReferenceInput>
                <NumberInput label="Media Limit For Subtitle" source={`contentRows[${index}].templateConfiguration.mediaLimitForSubtitle`} />
            </Fragment>
        ) : null
    );
};

MediaStripBannerStandard.propTypes = {
    contentRow: PropTypes.object,
};

MediaStripBannerStandard.defaultProps = {
    contentRow: null,
};
