import React from 'react';
import PropTypes from 'prop-types';
import {
    List,
    Edit,
    Create,
    EditButton,
    SimpleForm,
    TextInput,
    ReferenceInput,
    Labeled,
} from 'react-admin';
import {Field} from 'redux-form';
import {ColorInput} from 'react-admin-color-input';
import {Card, CardActions, Typography, ListItem, ListItemAvatar, Avatar, ListItemText, Chip} from '@material-ui/core';
import MUIList from '@material-ui/core/List';
import config from '../config';

import AssetBrowser from '../asset-library/AssetBrowser';
import SetDefaultButton from './SetDefaultButton';

const cardStyle = {
    width: 300,
    minHeight: 300,
    margin: '0.5em',
    padding: '0.5em',
    display: 'inline-block',
    verticalAlign: 'top',
};

const ThemeGrid = props => {
    const {
        ids,
        data,
        basePath,
    } = props;

    return (
        <div style={{margin: '1em'}}>
            {ids.map(id => (
                <Card key={id} style={cardStyle}>
                    <Typography style={{marginLeft: '1em', marginTop: '1em'}} variant="title">
                        {data[id].title}
                        {data[id].default
                            && <Chip style={{marginLeft: 6}} label="Default" />
                        }
                    </Typography>
                    <MUIList dense>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar
                                    src={`${config.PUBLISHED_CONFIG_STORAGE_URI}/${config.PUBLISHED_CONFIG_STORAGE_CONTAINER}/${data[id].logo.path}`}
                                    style={{width: 60, height: 60}}
                                />
                            </ListItemAvatar>
                            <ListItemText
                                primary="Logo"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar style={{backgroundColor: data[id].header_footer_colour}} />
                            </ListItemAvatar>
                            <ListItemText
                                primary="Header & Footer Colour"
                                secondary={data[id].header_footer_colour}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar style={{backgroundColor: data[id].header_footer_text_colour}} />
                            </ListItemAvatar>
                            <ListItemText
                                primary="Header & Footer Text Colour"
                                secondary={data[id].header_footer_text_colour}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar style={{backgroundColor: data[id].menu_colour}} />
                            </ListItemAvatar>
                            <ListItemText
                                primary="Menu Colour"
                                secondary={data[id].menu_colour}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar style={{backgroundColor: data[id].menu_hover_colour}} />
                            </ListItemAvatar>
                            <ListItemText
                                primary="Menu Hover Colour"
                                secondary={data[id].menu_hover_colour}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar style={{backgroundColor: data[id].menu_text_colour}} />
                            </ListItemAvatar>
                            <ListItemText
                                primary="Menu Text Colour"
                                secondary={data[id].menu_text_colour}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar style={{backgroundColor: data[id].more_button_colour}} />
                            </ListItemAvatar>
                            <ListItemText
                                primary="More Button Colour"
                                secondary={data[id].more_button_colour}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar style={{backgroundColor: data[id].more_button_hover_colour}} />
                            </ListItemAvatar>
                            <ListItemText
                                primary="More Button Hover Colour"
                                secondary={data[id].more_button_hover_colour}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar style={{backgroundColor: data[id].more_button_text_state}} />
                            </ListItemAvatar>
                            <ListItemText
                                primary="More Button Text State"
                                secondary={data[id].more_button_text_state}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar style={{backgroundColor: data[id].more_button_text_hover_colour}} />
                            </ListItemAvatar>
                            <ListItemText
                                primary="More Button Text Hover Colour"
                                secondary={data[id].more_button_text_hover_colour}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar style={{backgroundColor: data[id].spinner_colour}} />
                            </ListItemAvatar>
                            <ListItemText
                                primary="Spinner Colour"
                                secondary={data[id].spinner_colour}
                            />
                        </ListItem>
                    </MUIList>
                    <CardActions style={{textAlign: 'right'}}>
                        <EditButton resource="posts" basePath={basePath} record={data[id]} />
                    </CardActions>
                </Card>
            ))}
        </div>
    );
};

ThemeGrid.propTypes = {
    ids: PropTypes.array,
    data: PropTypes.object,
    basePath: PropTypes.string,
};

ThemeGrid.defaultProps = {
    ids: null,
    data: null,
    basePath: '',
};

export const ThemeList = props => (
    <List {...props} sort={{field: '_id', order: 'DESC'}}>
        <ThemeGrid />
    </List>
);

const actionsStyle = {
    'zIndex': 2,
    'display': 'inline-block',
    'float': 'right',
};

const ThemeEditActions = ({basePath, data}) => (
    <CardActions style={actionsStyle}>
        <SetDefaultButton record={data} />
    </CardActions>
);

ThemeEditActions.propTypes = {
    data: PropTypes.object,
    basePath: PropTypes.string,
};

ThemeEditActions.defaultProps = {
    data: null,
    basePath: '',
};

export const ThemeEdit = props => (
    <Edit {...props} actions={<ThemeEditActions />}>
        <SimpleForm>
            <TextInput source="title" />
            <ReferenceInput label="Logo" source="logo" reference="assets" perPage={6}>
                <Labeled>
                    <Field name="asset" component={AssetBrowser} />
                </Labeled>
            </ReferenceInput>
            <ColorInput source="header_footer_colour" />
            <ColorInput source="header_footer_text_colour" />
            <ColorInput source="menu_colour" />
            <ColorInput source="menu_hover_colour" />
            <ColorInput source="menu_text_colour" />
            <ColorInput source="more_button_colour" />
            <ColorInput source="more_button_hover_colour" />
            <ColorInput source="more_button_text_state" />
            <ColorInput source="more_button_text_hover_colour" />
            <ColorInput source="spinner_colour" />
        </SimpleForm>
    </Edit>
);

export const ThemeCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput source="title" />
            <ReferenceInput label="Logo" source="logo" reference="assets" perPage={6}>
                <Labeled>
                    <Field name="asset" component={AssetBrowser} />
                </Labeled>
            </ReferenceInput>
            <ColorInput source="header_footer_colour" />
            <ColorInput source="header_footer_text_colour" />
            <ColorInput source="menu_colour" />
            <ColorInput source="menu_hover_colour" />
            <ColorInput source="menu_text_colour" />
            <ColorInput source="more_button_colour" />
            <ColorInput source="more_button_hover_colour" />
            <ColorInput source="more_button_text_state" />
            <ColorInput source="more_button_text_hover_colour" />
            <ColorInput source="spinner_colour" />
        </SimpleForm>
    </Create>
);
