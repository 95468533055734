import React from 'react';
import PropTypes from 'prop-types';
import {
    List,
    Edit,
    Create,
    SimpleForm,
    ImageInput,
    ImageField,
    EditButton,

} from 'react-admin';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';

import config from '../config';

const AssetGrid = props => {
    const {
        ids,
        data,
        basePath,
    } = props;

    return (
        <GridList cellHeight={180} cols={3}>
            {ids.map(id => (
                <GridListTile key={`asset-tile-${id}`}>
                    <img alt={id} src={`${config.PUBLISHED_CONFIG_STORAGE_URI}/${config.PUBLISHED_CONFIG_STORAGE_CONTAINER}/${data[id].path}`} />
                    <GridListTileBar
                        actionIcon={
                            <EditButton resource="assets" basePath={basePath} record={data[id]} />
                        }
                    />
                </GridListTile>
            ))}
        </GridList>
    );
};

AssetGrid.propTypes = {
    ids: PropTypes.array,
    data: PropTypes.object,
    basePath: PropTypes.string,
};

AssetGrid.defaultProps = {
    ids: null,
    data: null,
    basePath: '',
};

export const AssetList = props => (
    <List {...props} perPage={15} sort={{field: '_id', order: 'DESC'}}>
        <AssetGrid />
    </List>
);

export const AssetEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <ImageInput source="path" label="Asset">
                <ImageField source="src" title="title" />
            </ImageInput>
        </SimpleForm>
    </Edit>
);

export const AssetCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <ImageInput source="path" label="Asset">
                <ImageField source="src" title="title" />
            </ImageInput>
        </SimpleForm>
    </Create>
);
