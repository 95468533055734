import React from 'react';
import {Admin, Resource} from 'react-admin';
import JssProvider from 'react-jss/lib/JssProvider';
import ContentPageIcon from '@material-ui/icons/ViewStream';
import MenuIcon from '@material-ui/icons/List';
import CatalogueIcon from '@material-ui/icons/Translate';
import VersionIcon from '@material-ui/icons/LinearScale';
import AssetIcon from '@material-ui/icons/Image';
import ThemeIcon from '@material-ui/icons/Colorize';

import {MenuList, MenuEdit} from './menus/adminView';
import {ContentPageList, ContentPageEdit, ContentPageCreate} from './content-pages/adminView';
import {TranslationList, TranslationEdit, TranslationCreate} from './translations/adminView';
import {ThemeList, ThemeEdit, ThemeCreate} from './themes/adminView';
import {AssetList, AssetEdit, AssetCreate} from './asset-library/adminView';
import {VersionList, VersionShow, VersionCreate} from './versions/adminView';

import contentRowFetchReducer from './content-pages/contentRowPropertiesFetchReducer';
import {collectionFetchWatcherSaga, genresFetchWatcherSaga} from './content-pages/contentRowPropertiesFetchSagas';

import wlsTheme from './template/wlsTheme';
import authProvider from './security/authProvider';
import config from './config';
import configRestClient from './data-provider/restClient';
import LoginPage from './security/Login';

import {generateClassName} from './customClassNameGenerator';

const App = () => (
    <JssProvider generateClassName={generateClassName}>
        <Admin
            theme={wlsTheme}
            title="WLS Configurator"
            authProvider={authProvider}
            dataProvider={
                configRestClient(
                    `${config.CONFIG_STORAGE_URI}/${config.CONFIG_STORAGE_ENV}`, config.CONFIG_STORAGE_KEY
                )
            }
            loginPage={LoginPage}
            customReducers={{
                contentRowProperties: contentRowFetchReducer,
            }}
            customSagas={[
                collectionFetchWatcherSaga,
                genresFetchWatcherSaga,
            ]}
        >
            <Resource name="content-pages" list={ContentPageList} edit={ContentPageEdit} create={ContentPageCreate} icon={ContentPageIcon} options={{label: 'Content Pages'}} />
            <Resource name="menus" list={MenuList} edit={MenuEdit} icon={MenuIcon} />
            <Resource name="translations" list={TranslationList} edit={TranslationEdit} create={TranslationCreate} icon={CatalogueIcon} />
            <Resource name="themes" list={ThemeList} edit={ThemeEdit} create={ThemeCreate} icon={ThemeIcon} options={{label: 'Themes'}} />
            <Resource name="assets" list={AssetList} edit={AssetEdit} create={AssetCreate} icon={AssetIcon} options={{label: 'Asset Library'}} />
            <Resource name="versions" list={VersionList} show={VersionShow} create={VersionCreate} icon={VersionIcon} />
        </Admin>
    </JssProvider>
);

export default App;
