import React from 'react';
import {
    List,
    Edit,
    Create,
    Datagrid,
    TextField,
    EditButton,
    SimpleForm,
    TextInput,
    ArrayInput,
    DisabledInput,
    SimpleFormIterator,
} from 'react-admin';

export const TranslationList = props => (
    <List {...props} sort={{field: '_id', order: 'DESC'}}>
        <Datagrid>
            <TextField source="language" label="Language code" />
            <EditButton />
        </Datagrid>
    </List>
);

export const TranslationEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <DisabledInput source="language" label="Language code" />
            <ArrayInput source="catalogue" label="Catalogue">
                <SimpleFormIterator>
                    <TextInput source="key" />
                    <TextInput source="translation" />
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
);

export const TranslationCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="language" label="Language code" />
            <ArrayInput source="catalogue" label="Catalogue">
                <SimpleFormIterator>
                    <TextInput source="key" />
                    <TextInput source="translation" />
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
);
