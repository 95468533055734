import React from 'react';
import PropTypes from 'prop-types';
import {
    List,
    Show,
    Create,
    Datagrid,
    TextField,
    ShowButton,
    SimpleForm,
    LongTextInput,
    SimpleShowLayout,
    ArrayField,
    DateField,
    BooleanField,
} from 'react-admin';
import {CardActions} from '@material-ui/core';

import PublishButton from './PublishButton';

export const VersionCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="show">
            <LongTextInput source="note" />
        </SimpleForm>
    </Create>
);

export const VersionList = props => (
    <List {...props}>
        <Datagrid>
            <BooleanField source="published" />
            <TextField source="number" />
            <TextField source="note" />
            <TextField source="created" />
            <ShowButton />
        </Datagrid>
    </List>
);

const actionsStyle = {
    'zIndex': 2,
    'display': 'inline-block',
    'float': 'right',
};

const VersionShowActions = ({basePath, data}) => ((data && !data.published) ? (
    <CardActions style={actionsStyle}>
        <PublishButton record={data} />
    </CardActions>
) : null);

VersionShowActions.propTypes = {
    data: PropTypes.object,
    basePath: PropTypes.string,
};

VersionShowActions.defaultProps = {
    data: null,
    basePath: '',
};

export const VersionShow = props => (
    <Show actions={(<VersionShowActions />)} {...props}>
        <SimpleShowLayout>
            <TextField source="number" />
            <TextField source="note" />
            <TextField source="created" />
            <ArrayField source="blobs">
                <Datagrid>
                    <TextField source="name" />
                    <DateField source="lastModified" />
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
    </Show>
);
