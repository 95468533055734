import React from 'react';
import {
    List,
    Edit,
    Datagrid,
    EditButton,
    SimpleForm,
    SelectField,
    SelectInput,
} from 'react-admin';
import {Field} from 'redux-form';
import MenuEditor from './menuEditor';

export const MenuList = props => (
    <List {...props} sort={{field: '_id', order: 'DESC'}}>
        <Datagrid>
            <SelectField
                source="type"
                choices={[
                    {id: 'header_menu', name: 'Header Menu'},
                    {id: 'footer_menu', name: 'Footer Menu'},
                ]}
            />
            <EditButton />
        </Datagrid>
    </List>
);

export const MenuEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <SelectInput
                source="type"
                choices={[
                    {id: 'header_menu', name: 'Header Menu'},
                    {id: 'footer_menu', name: 'Footer Menu'},
                ]}
            />
            <Field name="children" component={MenuEditor} />
        </SimpleForm>
    </Edit>
);
