import {createMuiTheme} from '@material-ui/core/styles';

const wlsTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#84203e',
        },
        secondary: {
            main: '#ff5900',
        },
        error: {
            main: '#b6283d',
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    typography: {
        useNextVariants: true,
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Arial',
            'sans-serif',
        ].join(','),
    },
    overrides: {
        MuiButton: { // override the styles of all instances of this component
            root: { // Name of the rule
                color: 'grey', // Some CSS
            },
        },
    },
});

export default wlsTheme;
