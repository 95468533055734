import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {
    Drawer,
    Button,
    CircularProgress,
} from '@material-ui/core';
import LibraryIcon from '@material-ui/icons/PhotoLibrary';

import config from '../config';

const PAGE_PREVIOUS = -1;
const PAGE_NEXT = 1;

class AssetBrowser extends Component {
    static propTypes = {
        isLoading: PropTypes.bool,
        input: PropTypes.object.isRequired,
        setPagination: PropTypes.func.isRequired,
        pagination: PropTypes.object.isRequired,
        choices: PropTypes.array.isRequired,
    };

    static defaultProps = {
        isLoading: false,
    };

    state = {
        openAssetBrowser: false,
    };

    selectAsset = asset => () => {
        const {
            input,
        } = this.props;
        this.setState({
            openAssetBrowser: false,
        });
        input.onChange(asset);
    };

    clearAsset = () => () => {
        const {
            input,
        } = this.props;
        input.onChange(null);
    };

    paginate = direction => () => {
        const {
            setPagination,
            pagination,
        } = this.props;

        setPagination({
            page: pagination.page + direction,
            perPage: pagination.perPage,
        });
    };

    toggleAssetBrowser = displayState => () => {
        this.setState({
            openAssetBrowser: displayState,
        });
    };

    render() {
        const {
            choices,
            isLoading,
            input,
        } = this.props;
        const selectedAssetPath = input.value.path;

        return (
            <Fragment>
                <Drawer anchor="right" open={this.state.openAssetBrowser} onClose={this.toggleAssetBrowser(false)}>
                    <div style={{position: 'relative', width: 320, height: '100%', padding: 10}}>
                        <div>
                            {choices.map((choice, i) => (
                                <Fragment key={`image-select-${i}`}>
                                    <div
                                        style={{
                                            width: 300,
                                            height: 200,
                                            backgroundSize: 'contain',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundImage: `url(${config.PUBLISHED_CONFIG_STORAGE_URI}/${config.PUBLISHED_CONFIG_STORAGE_CONTAINER}/${choice.path})`,
                                            backgroundPosition: 'center',
                                        }}
                                        onClick={this.selectAsset(choice)}
                                    />
                                    {(choices.length !== i + 1)
                                        && (
                                            <hr />
                                        )}
                                </Fragment>
                            ))}
                        </div>
                        { isLoading
                        && (
                            <CircularProgress size={20} />
                        )}
                        <div style={{position: 'absolute', bottom: 10, width: '92%'}}>
                            <Button style={{'position': 'relative', 'float': 'left'}} onClick={this.paginate(PAGE_PREVIOUS)} color="primary">
                                &lt; Previous
                            </Button>
                            <Button style={{'position': 'relative', 'float': 'right'}} onClick={this.paginate(PAGE_NEXT)} color="primary">
                                Next &gt;
                            </Button>
                        </div>
                    </div>
                </Drawer>
                <div>
                    {selectedAssetPath ? (
                        <div style={{
                            width: 380,
                            height: 240,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundImage: `url(${config.PUBLISHED_CONFIG_STORAGE_URI}/${config.PUBLISHED_CONFIG_STORAGE_CONTAINER}/${selectedAssetPath})`,
                        }}
                        />
                    ) : (
                        <div style={{width: 380, height: 240, textAlign: 'center', verticalAlign: 'middle', lineHeight: '240px', backgroundColor: 'grey'}}>
                            No asset chosen.
                        </div>
                    )}
                    <div style={{marginTop: '1em', display: 'flex', flexDirection: 'row'}}>
                        <Button variant="contained" size="small" onClick={this.toggleAssetBrowser(true)}>
                            <LibraryIcon />&nbsp;Open Asset Browser
                        </Button>
                        <Button style={{marginLeft: '1em'}} disabled={!selectedAssetPath} variant="contained" size="small" onClick={this.clearAsset()}>
                            <LibraryIcon />&nbsp;Clear Asset
                        </Button>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default AssetBrowser;
