import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {
    BooleanInput,
    SelectInput,
    FormDataConsumer,
    TextInput,
} from 'react-admin';
import Grid from '@material-ui/core/Grid/Grid';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import FormLabel from '@material-ui/core/FormLabel';
import {Field} from 'redux-form';

import {
    CONTENT_ROW_TEMPLATE_MEDIA_CAROUSEL_STANDARD,
    CONTENT_ROW_TEMPLATE_MEDIA_HERO_BANNER_STANDARD,
    CONTENT_ROW_TEMPLATE_HERO_BANNER_BACKGROUND_PLAYER,
    CONTENT_ROW_TEMPLATE_MEDIA_STRIP_BANNER_STANDARD,
    contentRowTypes,
    contentRowTemplates,
    CONTENT_ROW_TYPE_MEDIA_COLLECTION,
    CONTENT_ROW_TYPE_MEDIA_GENRE,
    CONTENT_ROW_TYPE_MEDIA_SET,
    CONTENT_ROW_TYPE_CUSTOM_DATA,
} from './contentRowClassifications';
import {
    MediaCarouselStandardForm,
    MediaHeroBannerStandard,
    HeroBannerBackgroundPlayer,
    MediaStripBannerStandard,
} from './TemplateForms';
import CollectionSelect from './CollectionSelect';
import GenreSelect from './GenreSelect';
import MediaSetInput from './MediaSetInput';
import CustomDataInput from './CustomDataInput';

class ContentRowForm extends Component {
    static propTypes = {
        contentRow: PropTypes.object,
        toggleContentRowForm: PropTypes.func.isRequired,
        toggleDeleteConfirmation: PropTypes.func.isRequired,
    };

    static defaultProps = {
        contentRow: null,
    };

    render() {
        const {
            contentRow,
            toggleContentRowForm,
            toggleDeleteConfirmation,
        } = this.props;

        return (
            (contentRow) ? (
                <div style={{padding: 20, width: 400}}>
                    <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="stretch"
                    >
                        <FormLabel>General</FormLabel>
                        <BooleanInput label="Display" source={`contentRows[${contentRow.position}].display`} />
                        <SelectInput
                            label="Type"
                            source={`contentRows[${contentRow.position}].type`}
                            choices={contentRowTypes}
                            optionText="label"
                            optionValue="key"
                        />
                        <FormDataConsumer>
                            {({formData, ...rest}) => {
                                switch (formData.contentRows[contentRow.position].type) {
                                    case CONTENT_ROW_TYPE_MEDIA_COLLECTION:
                                        return (
                                            <Field name={`contentRows[${contentRow.position}].value`} component={CollectionSelect} />
                                        );
                                    case CONTENT_ROW_TYPE_MEDIA_GENRE:
                                        return (
                                            <Field name={`contentRows[${contentRow.position}].value`} component={GenreSelect} />
                                        );
                                    case CONTENT_ROW_TYPE_MEDIA_SET:
                                        return (
                                            <Field name={`contentRows[${contentRow.position}].value`} component={MediaSetInput} />
                                        );
                                    case CONTENT_ROW_TYPE_CUSTOM_DATA:
                                        return (
                                            <Field name={`contentRows[${contentRow.position}].value`} component={CustomDataInput} />
                                        );
                                }
                            }}
                        </FormDataConsumer>
                        <SelectInput
                            label="Template"
                            source={`contentRows[${contentRow.position}].template`}
                            choices={contentRowTemplates}
                            optionText="label"
                            optionValue="key"
                        />
                        <FormLabel style={{marginTop: 30}}>Template Configuration</FormLabel>
                        <FormDataConsumer>
                            {({formData, ...rest}) => {
                                switch (formData.contentRows[contentRow.position].template) {
                                    case CONTENT_ROW_TEMPLATE_MEDIA_CAROUSEL_STANDARD:
                                        return (
                                            <MediaCarouselStandardForm
                                                contentRow={contentRow}
                                            />
                                        );
                                    case CONTENT_ROW_TEMPLATE_MEDIA_HERO_BANNER_STANDARD:
                                        return (
                                            <MediaHeroBannerStandard
                                                contentRow={contentRow}
                                            />
                                        );
                                    case CONTENT_ROW_TEMPLATE_HERO_BANNER_BACKGROUND_PLAYER:
                                        return (
                                            <HeroBannerBackgroundPlayer
                                                contentRow={contentRow}
                                            />
                                        );
                                    case CONTENT_ROW_TEMPLATE_MEDIA_STRIP_BANNER_STANDARD:
                                        return (
                                            <MediaStripBannerStandard
                                                contentRow={contentRow}
                                            />
                                        );
                                }
                            }}
                        </FormDataConsumer>
                        <Grid
                            container
                            direction="row"
                            justify="space-around"
                            alignItems="flex-start"
                        >
                            <Button style={{width: '47%'}} variant="contained" color="primary" onClick={toggleContentRowForm(false)}>
                                <SaveIcon />&nbsp;Done
                            </Button>
                            <Button style={{width: '47%'}} variant="contained" color="secondary" onClick={toggleDeleteConfirmation(true)}>
                                <DeleteIcon />&nbsp;Remove
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            ) : null
        );
    }
}

export default ContentRowForm;
